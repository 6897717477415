<template>
  <div class="w-full">
    <table class="relative w-full text-sm whitespace-nowrap">
      <thead>
        <tr ref="header" class="italic text-gray-600">
          <th
            v-for="(col, index) of columns"
            :key="col.field"
            class="sticky p-0 font-normal text-left bg-gray-50"
          >
            <div
              class="px-2 pt-3 pb-1 space-x-1 w-full border-b"
              :class="{ 'pl-4': index === 0, 'pr-4': !hasActions && index === columns.length - 1, 'text-right': col.numerical, 'border-gray-500': !isLoading }"
            >
              <span v-if="!col.numerical" v-text="col.title" />
              <sort-button
                v-if="col.sortable"
                :field="col.sortable"
                :sorting="sorting"
                :isLoading="isLoading"
                @sort="sort"
              />
              <span v-if="col.numerical" v-text="col.title" />
            </div>
          </th>
          <th v-if="hasActions" class="sticky p-0 bg-gray-50">
            <div
              class="px-2 pt-3 pb-1 w-full h-full border-b"
              :class="{ 'border-gray-500': !isLoading }"
            >&nbsp;</div>
          </th>
        </tr>
      </thead>
      <tbody v-if="isLoading">
        <tr>
          <td colspan="100" class="p-0">
            <div class="w-full loading-bar"><div /><div /></div>
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr
          v-for="(entry, index) of sortedList"
          :key="entry._id"
          class="hover:bg-gray-200 border-b"
          :class="[
            !shownIDs.includes(entry._id) && 'hidden',
            (entry.__isDone || entry.isChecked) && 'bg-green-100 !hover:bg-green-200',
            (!entry.__isDone && entry.isInInvestigation) && 'bg-yellow-100 !hover:bg-yellow-200',
            isRefreshing && index <= 100 && 'loading'
          ]"
        >
          <td class="py-1 pr-2 pl-4 tabular-nums" v-text="entry.itemID" />
          <td
            class="py-1 px-2 w-full max-w-xs truncate"
            :title="entry.description"
            v-text="entry.description"
          />
          <td
            class="py-1 px-2 tabular-nums text-right"
            v-html="entry.__nominalQuantity"
          />
          <td
            class="py-1 px-2 tabular-nums text-right"
            :class="{ 'text-green-500': entry.isChecked }"
            v-html="entry.__currentQuantity"
          />
          <td class="py-1 px-2 tabular-nums text-right" v-html="entry.__diffQuantity" />
          <td class="py-1 px-2 tabular-nums text-right" v-html="entry.__nominalWorth" />
          <td class="py-1 px-2 tabular-nums text-right" v-html="entry.__currentWorth" />
          <td class="py-1 px-2 tabular-nums text-right" v-html="entry.__diffWorth" />
          <td class="py-1 pr-3 pl-2 text-right">
            <button
              class="py-1 px-1 text-xs leading-none focus:outline-none fas fa-check"
              :class="entry.isChecked ? 'text-green-500 hover:text-primary-600' : 'text-gray-400 hover:text-primary-600'"
              title="Ist-Menge bestätigen"
              @click="confirmCount(entry._id)"
            />
            <a
              :href="`/articles/${entry._id}/`"
              class="py-1 px-1 text-xs leading-none text-gray-400 focus:text-gray-800 focus:outline-none hover:text-primary-600 fas fa-box"
              title="Artikel anzeigen"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  // group-hover:hidden group-hover:block
  import SortButton from '@krknet/fe/components/richTable/sortButton.vue'

  const defaultSorting = { field: 'itemID', direction: -1 }

  export default {
    name: 'RichTable',
    components: { SortButton },
    props: {
      isLoading: { type: Boolean, required: false, default: false },
      isRefreshing: { type: Boolean, required: false, default: false },
      entries: { type: Array, required: false, default: () => [] },
      columns: { type: Array, required: true },
      hasActions: { type: Boolean, required: false, default: false },
      shownIDs: { type: Array, required: true }
    },
    emits: ['confirmCount'],
    data () {
      return {
        sorting: this._getSorting()
      }
    },
    computed: {
      sortedList () {
        const output = this.entries.slice()
        if (this.entries.length === 0) return output

        const field = this.sorting.field
        const sorterAlph = (a, b) => `${a[field]}`.localeCompare(`${b[field]}`) * this.sorting.direction
        const sortNum = (a, b) => (a[field] < b[field] ? -1 : 1) * this.sorting.direction
        const sorter = typeof output[0][field] === 'string' ? sorterAlph : sortNum

        return output.sort(sorter)
      }
    },
    methods: {
      confirmCount (articleID) {
        this.$emit('confirmCount', articleID)
      },
      _getSorting () {
        const hit = this.columns.find(d => d.initialSorting !== undefined)
        if (hit === undefined) return defaultSorting
        const direction = Number.parseInt(hit.initialSorting)
        return { field: hit.sortable, direction: Number.isNaN(direction) ? 1 : direction }
      },
      sort (sorting) {
        this.sorting = sorting
      }
    }
  }
</script>

<style>
  th {
    top: 52px;
  }

  .loading-bar {
    height: 2px;
    background-color: #bad2ec;
    overflow: hidden;
    position: relative;
    transition: 0.2s;
  }

  .loading-bar > div {
    background-color: #1867c0;
    bottom: 0;
    height: inherit;
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    will-change: left, right;
  }

  .loading-bar > div:first-child {
    animation: indeterminate;
    animation-duration: 2.2s;
    animation-iteration-count: infinite;
  }

  .loading-bar > div:last-child {
    animation: indeterminate-short;
    animation-duration: 2.2s;
    animation-iteration-count: infinite;
  }

  @keyframes indeterminate {
    0% { left: -90%; right: 100%; }
    60% { left: -90%; right: 100%; }
    100% { left: 100%; right: -35%; }
  }

  @keyframes indeterminate-short {
    0% { left: -200%; right: 100%; }
    60% { left: 107%; right: -8%; }
    100% { left: 107%; right: -8%; }
  }
</style>
