<template>
  <v-page>
    <div class="container py-6">
      <div class="flex -mx-4">
        <div class="px-4 text-right" :class="{ 'loading': isLoading || isRefreshing }">
          <p class="text-sm font-normal tracking-wide leading-6 text-gray-500 uppercase whitespace-nowrap">absolute Wertabweichung</p>
          <p class="flex justify-end items-start mt-1 text-4xl font-semibold tabular-nums leading-8 text-gray-800 whitespace-nowrap">
            {{ absDiffWorth }}
            <span class="ml-0.5 text-sm font-medium leading-5 text-gray-500">€</span>
          </p>
        </div>
        <div class="px-4 text-right" :class="{ 'loading': isLoading || isRefreshing }">
          <p class="text-sm font-normal tracking-wide leading-6 text-gray-500 uppercase whitespace-nowrap">summierte Wertabweichung</p>
          <p class="flex justify-end items-start mt-1 text-4xl font-semibold tabular-nums leading-8 text-gray-800 whitespace-nowrap">
            {{ sumDiffWorth }}
            <span class="ml-0.5 text-sm font-medium leading-5 text-gray-500">€</span>
          </p>
        </div>
        <div class="flex-grow" />
        <div
          v-if="!searchTerm"
          class="px-4 text-right"
          :class="{ 'loading': isLoading || isRefreshing }"
        >
          <p class="text-sm font-normal tracking-wide leading-6 text-gray-500 uppercase">Export</p>
          <a
            :href="`/api/status/?export=totals&${queryString}`"
            target="_BLANK"
            class="py-0.5 px-3 text-sm btn btn-tertiary"
          >
            <i class="mr-1 fal fa-fw fa-long-arrow-down" />
            <span>XLSX</span>
          </a>
        </div>
        <div class="px-4 text-right" :class="{ 'loading': isLoading || isRefreshing }">
          <p class="text-sm font-normal tracking-wide leading-6 text-gray-500 uppercase">Suche</p>
          <search-box ref="searchBox" @search="onSearch" />
          <p class="text-xs tabular-nums tracking-wider leading-6 text-gray-400">{{ shownIDs.length }} / {{ filteredList.length }}</p>
        </div>
      </div>
      <div>
        <h5 class="mt-2 text-sm text-gray-400">Filter</h5>
        <div class="flex" :class="{ 'loading': isLoading }">
          <label class="flex items-center my-1 mr-8 text-sm whitespace-nowrap cursor-pointer select-none">
            <v-toggle v-model="filters.doShowPerfect" />
            <span class="ml-2">Abgeschlossene anzeigen</span>
          </label>
          <label class="flex items-center my-1 mr-8 text-sm whitespace-nowrap cursor-pointer select-none">
            <v-toggle v-model="filters.doShowChecked" />
            <span class="ml-2">Bestätigte anzeigen</span>
          </label>
          <label class="flex items-center my-1 mr-8 text-sm whitespace-nowrap cursor-pointer select-none">
            <v-toggle v-model="filters.doShowUnscanned" />
            <span class="ml-2">Unerfasste anzeigen</span>
          </label>
        </div>
      </div>
      <div v-if="warehouses.length > 1">
        <h5 class="mt-2 text-sm text-gray-400">Warenlager</h5>
        <div class="flex flex-wrap" :class="{ 'loading': isLoading }">
          <label
            v-for="warehouse of warehouses"
            :key="warehouse"
            class="flex items-center my-1 mr-8 text-sm whitespace-nowrap cursor-pointer select-none"
            @click.ctrl.prevent="singleWarehouse(warehouse)"
          >
            <v-toggle
              :modelValue="filters.selectedWarehouses.includes(warehouse)"
              @update:modelValue="toggleWarehouse(warehouse)"
            />
            <span class="ml-2" v-text="warehouse" />
          </label>
        </div>
      </div>
      <div v-if="categories.length > 1">
        <h5 class="mt-2 text-sm text-gray-400">Kategorien</h5>
        <div class="flex flex-wrap" :class="{ 'loading': isLoading }">
          <label
            v-for="category of categories"
            :key="category"
            class="flex items-center my-1 mr-8 text-sm whitespace-nowrap cursor-pointer select-none"
            @click.ctrl.prevent="singleCategory(category)"
          >
            <v-toggle
              :modelValue="filters.selectedCategories.includes(category)"
              @update:modelValue="toggleCategory(category)"
            />
            <span class="ml-2" v-text="category" />
          </label>
        </div>
      </div>

      <div class="mt-8">
        <rich-table
          :isLoading="isLoading"
          :isRefreshing="isRefreshing"
          :columns="columns"
          :entries="list"
          :shownIDs="shownIDs"
          hasActions
          @confirmCount="confirmCount"
        />
      </div>
    </div>
  </v-page>
</template>

<script>
  import RichTable from './richTable.vue'
  import SearchBox from '@/searchBox.vue'

  import { formatNum } from '@/helpers'

  export default {
    name: 'StatusPage',
    components: { RichTable, SearchBox },
    data () {
      return {
        isLoading: true,
        isRefreshing: false,
        searchTerm: '',
        list: [],
        warehouses: [],
        categories: [],
        filters: {
          doShowPerfect: false,
          doShowChecked: true,
          doShowUnscanned: true,
          selectedCategories: [],
          selectedWarehouses: [],
          ...parseFilters(document.location.search.substring(1))
        },
        columns: [
          { title: 'Artikel-Nr', field: 'itemID', sortable: 'itemID', initialSorting: 1 },
          { title: 'Beschreibung', field: '__description' },
          { title: 'Soll-Menge', field: '__nominalQuantity', sortable: 'nominalQuantity', numerical: true },
          { title: 'Ist-Menge', field: '__currentQuantity', sortable: 'currentQuantity', numerical: true },
          { title: 'Diff-Menge', field: '__diffQuantity', sortable: 'diffQuantity', numerical: true },
          { title: 'Soll-Wert', field: '__nominalWorth', sortable: 'nominalWorth', numerical: true },
          { title: 'Ist-Wert', field: '__currentWorth', sortable: 'currentWorth', numerical: true },
          { title: 'Diff-Wert', field: '__diffWorth', sortable: 'diffWorth', numerical: true }
        ]
      }
    },
    computed: {
      queryString () {
        return [
          this.filters.doShowUnscanned && 'unscanned',
          this.filters.doShowPerfect && 'perfect',
          this.filters.doShowChecked && 'checked',
          ...this.filters.selectedCategories.map(d => d && `categories[]=${encodeURIComponent(d)}`)
        ]
          .filter(Boolean)
          .join('&')
      },
      sumDiffWorth () {
        return formatNum(this.searchedList.reduce((acc, curr) => acc + curr.diffWorth, 0))
      },
      absDiffWorth () {
        return formatNum(this.searchedList.reduce((acc, curr) => acc + Math.abs(curr.diffWorth), 0))
      },
      isShowAll () {
        if (!this.filters.doShowPerfect) return false
        if (!this.filters.doShowChecked) return false
        if (!this.filters.doShowUnscanned) return false
        if (this.filters.selectedWarehouses.length !== this.categories.length) return false

        return true
      },
      filteredList () {
        if (this.isShowAll) return this.list

        return this.list.filter(d => {
          if (!this.filters.doShowUnscanned && d.currentQuantity === 0) return false
          if (!this.filters.doShowPerfect && d.__isDone) return false
          if (!this.filters.doShowChecked && d.isChecked) return false
          if (!this.filters.selectedCategories.includes(d.category)) return false
          return true
        })
      },
      searchedList () {
        if (this.searchTerm.length === 0) return this.filteredList
        const regex = new RegExp(this.searchTerm.toLowerCase())

        return this.filteredList.filter(d => regex.test(d.__search || ''))
      },
      shownIDs () {
        return this.searchedList.map(d => d._id)
      }
    },
    watch: {
      filters: {
        handler () {
          const searchParams = new URLSearchParams()

          searchParams.set('perfect', this.filters.doShowPerfect)
          searchParams.set('checked', this.filters.doShowChecked)
          searchParams.set('unscanned', this.filters.doShowUnscanned)

          if (this.filters.selectedWarehouses.length > 0 && this.warehouses.length > 0 && this.filters.selectedWarehouses.length < this.warehouses.length) {
            searchParams.set('warehouses', this.filters.selectedWarehouses.join('_'))
          }

          if (this.filters.selectedCategories.length > 0 && this.categories.length > 0 && this.filters.selectedCategories.length < this.categories.length) {
            searchParams.set('categories', this.filters.selectedCategories.join('_'))
          }

          const newURL = `${baseURL}?${searchParams.toString()}`
          window.history.replaceState({ path: newURL }, '', newURL)
        },
        deep: true
      }
    },
    created () {
      this.fetch()
      const socket = window.io('/', { path: '/ws' })
      socket.on('newCounts', this.onNewCounts)
      socket.on('newPrices', this.fetchStatus)
      socket.on('newCheck', this.fetchStatus)
      socket.on('newInvestigation', this.fetchStatus)
    },
    methods: {
      singleCategory (category) {
        this.filters.selectedCategories = [category]
      },
      toggleCategory (category) {
        let selectedCategories = this.filters.selectedCategories.slice()
        if (selectedCategories.includes(category)) {
          selectedCategories = selectedCategories.filter(d => d !== category)
        } else {
          selectedCategories.push(category)
        }

        if (selectedCategories.length === 0) selectedCategories = this.categories.slice()
        this.filters.selectedCategories = selectedCategories
      },
      singleWarehouse (warehouse) {
        this.isRefreshing = true
        this.filters.selectedWarehouses = [warehouse]
        this.fetchStatus().then(() => { this.isRefreshing = false })
      },
      toggleWarehouse (warehouse) {
        let selectedWarehouses = this.filters.selectedWarehouses.slice()
        if (selectedWarehouses.includes(warehouse)) {
          selectedWarehouses = selectedWarehouses.filter(d => d !== warehouse)
        } else {
          selectedWarehouses.push(warehouse)
        }

        if (selectedWarehouses.length === 0) selectedWarehouses = this.warehouses.slice()

        this.isRefreshing = true
        this.filters.selectedWarehouses = selectedWarehouses
        this.fetchStatus().then(() => { this.isRefreshing = false })
      },
      onSearch (searchTerm) {
        this.searchTerm = searchTerm.trim()
      },
      clearSearch () {
        if (this.$refs.searchBox) this.$refs.searchBox.clearSearch()
      },
      confirmCount (articleID) {
        window.axios.post(`/api/articles/${articleID}/check/`) // .then(res => { this.fetchStatus() })
      },
      fetch () {
        this.isLoading = true

        this.warehouses = []
        this.list = []

        Promise.all([
          this.fetchStatus(),
          this.fetchCategories(),
          this.fetchWarehouses()
        ])
          .then(() => {
            this.isLoading = false
            if (!this.filters.selectedWarehouses.length) this.filters.selectedWarehouses = this.warehouses.slice()
            if (!this.filters.selectedCategories.length) this.filters.selectedCategories = this.categories.slice()
          })
      },
      fetchStatus () {
        const startStatus = this.filters.selectedWarehouses.length === 0 ? null : JSON.stringify(this.filters.selectedWarehouses)
        return window.axios
          .get('/api/status/', { params: { warehouses: this.filters.selectedWarehouses.length === this.warehouses.length ? undefined : this.filters.selectedWarehouses } })
          .then(res => {
            const endStatus = JSON.stringify(this.filters.selectedWarehouses)
            if (!startStatus || startStatus === endStatus) this.list = res.data.map(enrichStatus)
          })
      },
      fetchCategories () {
        return window.axios.get('/api/articles/?categories').then(res => { this.categories = res.data.sort() })
      },
      fetchWarehouses () {
        return window.axios.get('/api/locations/warehouses/').then(res => { this.warehouses = res.data.sort() })
      },
      onNewCounts (newCounts) {
        this.fetchStatus()
      }
    }
  }

  const parseFilters = input => {
    const searchParams = new URLSearchParams(input)

    const output = {}

    if (searchParams.has('perfect')) output.doShowPerfect = searchParams.get('perfect') === 'true'
    if (searchParams.has('checked')) output.doShowChecked = searchParams.get('checked') === 'true'
    if (searchParams.has('unscanned')) output.doShowUnscanned = searchParams.get('unscanned') === 'true'

    if (searchParams.has('warehouses')) output.selectedWarehouses = searchParams.get('warehouses').split('_').filter(Boolean)
    if (searchParams.has('categories')) output.selectedCategories = searchParams.get('categories').split('_').filter(Boolean)

    return output
  }

  const baseURL = `${window.location.protocol}//${window.location.host}${window.location.pathname}`

  const enrichStatus = d => Object.freeze({
    ...d,
    __search: [d._id, d.itemID].join(' | ').toLowerCase(),
    __isDone: d.diffQuantity === 0,
    __nominalQuantity: formatNum(d.nominalQuantity, { decimals: 2, html: 'text-gray-400' }),
    __nominalWorth: d.valuationPrice === 0 ? '<span class="text-red-600">0</span><span class="text-red-400">.00</span>' : formatNum(d.nominalWorth, { decimals: 2, html: 'text-gray-400' }),
    __currentQuantity: formatNum(d.currentQuantity, { decimals: 2, html: 'text-gray-400' }),
    __currentWorth: formatNum(d.currentWorth, { decimals: 2, html: 'text-gray-400' }),
    __diffQuantity: d.diffQuantity === 0 ? '<span class="text-green-700">0</span><span class="text-green-600 opacity-75">.00</span>' : formatNum(d.diffQuantity, { decimals: 2, html: 'text-gray-400', forcePrefix: true }),
    __diffWorth: formatNum(d.diffWorth, { decimals: 2, html: 'text-gray-400', forcePrefix: true })
  })
</script>
