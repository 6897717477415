import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"
import 'virtual:windi.css'
import '/layout/main.css'
import App from '/app/app/frontend/pages/landing/Page.vue'

import { createApp } from 'vue'
import vAlert from '/app/app/frontend/components/global/v-alert.vue'
import vInput from '/app/app/frontend/components/global/v-input.vue'
import vModal from '/app/app/frontend/components/global/v-modal.vue'
import vPage from '/app/app/frontend/components/global/v-page/index.vue'
import vToggle from '/app/app/frontend/components/global/v-toggle.vue'
import vWarning from '/app/app/frontend/components/global/v-warning.vue'

const app = createApp(App)
  .component('v-alert', vAlert)
  .component('v-input', vInput)
  .component('v-modal', vModal)
  .component('v-page', vPage)
  .component('v-toggle', vToggle)
  .component('v-warning', vWarning)

if (window.sentryDSN) {
  Sentry.init({
    app,
    dsn: window.sentryDSN,
    release: `inventur@${window.__version}`,
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['/api/', /^\//]
      })
    ],
    tracesSampleRate: 1
  })
}

app.mount('#app')